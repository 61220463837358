import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import MagicLink from "../MagicLink";
import { rem, media, Font, responsive, Color } from "../../utils/style";

const Container = styled.div.attrs({
  className: "col-6 col-sm-4 col-md-3",
})`
  margin-bottom: ${rem(40)};
  ${Font.circular};

  ${responsive.md`
    margin-bottom: ${rem(80)};
  `}
`;

const IngredientName = styled.h3.attrs({
  className: "text-center",
})`
  ${Font.circular}
  color: ${Color.ritualBlue};
  margin-bottom: 4px;
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  letter-spacing: 0px;

  ${responsive.md`
    margin-bottom: 8px;
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
  `}
`;

const IngredientLocation = styled.p`
  ${Font.circular}
  text-align: center;
  margin-bottom: 0;
  font-weight: 300;
  font-size: ${rem(14)};
  line-height: ${rem(20)};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(22)};
  `}
`;

const IngredientLink = styled(MagicLink)`
  margin: auto;
  text-align: center;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  width: calc(100% - ${rem(10)});
  margin-bottom: ${rem(24)};

  /**
    * Fixes transform bug seen in Safari that ignores border-radius during the
    * transition.
    */
  position: relative;
  z-index: 1;

  ${media.notDesktop`
    width: calc(100% - ${rem(6)});
    margin-bottom: ${rem(18)}
  `};
`;

const Image = styled(Img)`
  transition: transform 0.3s ease-out;

  ${IngredientLink}:hover & {
    transform: scale(1.1);
  }
`;

const IngredientSummary = props => {
  const { ingredient, productSku } = props;
  const { image, name, manufacturingLocation } = ingredient;

  return (
    <Container>
      <IngredientLink
        to={`/ingredients/${ingredient.slug}`}
        state={productSku ? { productSku } : {}}
        ariaLabel={`Read more about ${name}`}
      >
        <ImageContainer>
          <Image
            fluid={image.fluid}
            loading="eager"
            fadeIn={false}
            alt={image.title}
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
        </ImageContainer>
        <IngredientName>{name}</IngredientName>
        <IngredientLocation>{manufacturingLocation}</IngredientLocation>
      </IngredientLink>
    </Container>
  );
};

export default IngredientSummary;
