import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Utils
import { rem, media, responsive, Color } from "../../utils/style";
import { variation } from "../../utils/launchDarkly";

// Components
import Helmet from "react-helmet";
import PageSEO from "../../components/seo/Page";
import Container from "../../components/Container";
import Row from "../../components/Row";
import IngredientSummary from "../../components/ingredients/IngredientSummary";
import IngredientsHeader from "../../components/ingredients/IngredientsHeader";
import OtherIngredientsModal from "../../components/ingredients/OtherIngredientsModal";
import ProductCategoryCards from "../../components/product/ProductCategoryCards";
import ArticlePreview from "../../components/hub/ArticlePreview";
import StickyProductNav from "../../components/global/StickyProductNav";
import RitualButton from "../../components/global/RitualButton";
import Text from "../../components/Text";

const Section = styled.section.attrs({
  className: "row",
})`
  margin-top: ${rem(64)};

  ${media.mobile`
    margin-top: ${rem(40)};
  `};
`;

const OtherIngredientsColumn = styled.div.attrs({
  className: "col-12",
})`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 40px 0;
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 0;
    padding-right: 0;
  `}

  ${responsive.md`
    margin: 40px 0 80px;
  `}

  &::before {
    content: "";
    width: 32px;
    height: 4px;
    background-color: ${Color.ritualBlue};
    position: absolute;
    top: 0;
    left: calc(50% - 16px);
  }
`;

const OtherIngredientsSubheader = styled.p`
  margin: 0 0 24px;
  font-weight: 500;
  text-align: center !important;

  width: 100%;
  letter-spacing: 0px;
  font-size: ${rem(18)};
  line-height: ${rem(28)};

  ${responsive.sm`
    width: 460px;
  `}

  ${responsive.md`
    width: 570px;
    letter-spacing: -0.2px;
    font-size: ${rem(22)};
    line-height: ${rem(32)};
  `}
`;

const ProductCategoryCardsContainer = styled.div`
  h3 {
    font-size: ${rem(24)};
    letter-spacing: -0.28px;
    line-height: ${rem(34)};
    text-align: center;
    margin-bottom: 24px;

    ${responsive.md`
      font-size: ${rem(40)};
      letter-spacing: -1.17px;
      line-height: ${rem(54)};
      margin-bottom:  56px;
    `}
  }
`;

export default class Ingredients extends React.Component {
  constructor(props) {
    super(props);

    const {
      pageTitle,
      pageDescription,
    } = props.data.allContentfulIngredientsPage.edges[0].node;

    this.state = {
      seo: {
        pagePath: "ingredients",
        title: pageTitle,
        description: pageDescription,
      },
      modalOpen: false,
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "All Ingredients",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: false,
    });
  }

  /*
    Takes a string, lowercases and dasherizes it, while checking for
    extra - at start/end.
    Example: Some-Place- would output some-place
  */
  getLocationPath(locationGroup) {
    return locationGroup
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
  }

  openIngredientsModal() {
    this.setState({
      modalOpen: true,
    });
  }

  closeIngredientsModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const {
      subheadText,
      ingredientsList,
      articles,
      otherIngredients,
    } = this.props.data.allContentfulIngredientsPage.edges[0].node;

    const { modalOpen } = this.state;

    // Set the default map page to be the location of the first Ingredient linked
    const locationGroup = ingredientsList[0].locationGroup;
    const locationPath = this.getLocationPath(locationGroup);
    const { location } = this.props;
    const article = articles[0];

    const productSku = location.state ? location.state.productSku : "";

    return (
      <>
        {productSku && (
          <StickyProductNav
            sku={productSku}
            ctaLocation={"Ingredient Product Cta"}
            scrollOffset={150}
            showLearnMore={true}
            showTopBar={true}
          />
        )}
        <Container>
          <PageSEO {...this.state.seo} />
          <Helmet bodyAttributes={{ class: "template" }} />
          <IngredientsHeader
            subheadText={subheadText}
            location={location}
            locationPath={locationPath}
            productSku={productSku}
          />
          <Section>
            {ingredientsList.map((ingredient, i) => {
              return (
                <IngredientSummary
                  key={`${i}`}
                  ingredient={ingredient}
                  productSku={productSku}
                />
              );
            })}
          </Section>

          {/** Other Ingredients */}
          <Row>
            <OtherIngredientsColumn>
              <OtherIngredientsSubheader>
                {otherIngredients.subheader}
              </OtherIngredientsSubheader>
              <RitualButton
                className="bordered"
                onClick={this.openIngredientsModal.bind(this)}
              >
                <Text
                  id="ingredients.button-see-other"
                  defaultMessage="See Other Ingredients"
                />
              </RitualButton>
            </OtherIngredientsColumn>
          </Row>
        </Container>
        <div className="mt-5">
          <ArticlePreview article={article} className="mobile-gutter" />
        </div>

        <ProductCategoryCardsContainer className="mt-7 mt-md-9 mb-7 mb-md-9">
          <h3>
            <Text
              id="category-card.section.heading"
              defaultMessage="Find Your Ritual"
            />
          </h3>
          <ProductCategoryCards />
        </ProductCategoryCardsContainer>

        <OtherIngredientsModal
          isOpen={modalOpen}
          onRequestClose={this.closeIngredientsModal.bind(this)}
          title={otherIngredients.title}
          subheader={otherIngredients.subheader}
          ingredients={otherIngredients.ingredients}
        />
      </>
    );
  }
}

export const contentfulProductFragment = graphql`
  fragment AllContentfulProductFragment on ContentfulProduct {
    id
    name {
      name
      childMarkdownRemark {
        rawMarkdownBody
      }
    }
  }
`;

export const ingredientsPageFragment = graphql`
  fragment IngredientsPageFragment on ContentfulIngredientsPage {
    subheadText
    pageTitle
    pageDescription
    otherIngredients {
      title
      subheader
      ingredients {
        name
        supplier
        location
        function
      }
    }
    articles {
      id
      title
      previewText
      slug
      __typename
      heroBackgroundColor
      heroImage {
        title
        fixed(width: 300, height: 300, quality: 90, cropFocus: LEFT) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      primaryContent {
        __typename
        ... on ContentfulContentGeneric {
          ...genericContentBlock
        }
        ... on ContentfulContentBlockquote {
          ...blockQuoteBlock
        }
        ... on ContentfulContentInterviewQA {
          ...articleQABlock
        }
        ... on ContentfulContentMythFact {
          ...articleMythFactBlock
        }
        ... on ContentfulContentInterviewStandaloneAnswer {
          ...articleAnswerBlock
        }
        ... on ContentfulContentStandaloneImage {
          ...standaloneImageBlock
        }
        ... on ContentfulContentInterviewBio {
          ...interviewerBioContentBlock
        }
      }
    }
  }
`;

export const query = graphql`
  query IngredientsListQuery($locale: String!) {
    allContentfulIngredientsPage(
      filter: {
        node_locale: { eq: $locale }
        contentful_id: { eq: "4wJg1DbUMwMUo0UuUS6cO2" }
      }
    ) {
      edges {
        node {
          ...IngredientsPageFragment
          ingredientsList {
            __typename
            name
            slug
            productClassification
            manufacturingLocation
            locationGroup
            image {
              title
              fluid(maxWidth: 240, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
